import React, { Component } from 'react'

class AboutUs extends Component {
  render() {
    return (
      <div className="about-us-container section">
        <div className="section-heading">About Us</div>
        <span>blah blah blah</span>
      </div>
    )
  }
}

export default AboutUs
